import React from "react";
import { Button, Container, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import NotFound from "./404.svg";

function PageNotFound() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      style={{
        height: "70vh",
        width: "100%",
        margin: "24px",
      }}
    >
      <Grid item>
        <Container
          style={{
            width: "250px",
          }}
        >
          <img
            src={NotFound}
            alt="Screenshot"
            className="floating-image"
            style={{
              width: "100%",
              marginBottom: "24px",
            }}
          />
        </Container>
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          style={{
            fontWeight: "bold",
          }}
        >
          Page Not Found
        </Typography>
      </Grid>
      <Grid item>
        <Button
          href="/"
          style={{
            padding: "0px",
            margin: "16px",
          }}
        >
          <button className="button">Go Home</button>
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageNotFound;
