import React from "react";
import { AppBar, Toolbar, Button, Container, Grid } from "@material-ui/core";
import bigLogo from "../assets/logo_max.svg";
import "../styles/styles.css";

function NavBar() {
  return (
    <AppBar position="relative" elevation={0} color="transparent">
      <Toolbar>
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button href="/">
                <img
                  src={bigLogo}
                  width="200px"
                  alt="Logo"
                  style={{
                    margin: "0.5rem",
                  }}
                ></img>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
