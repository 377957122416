import {
  CssBaseline,
  createTheme,
  MuiThemeProvider,
  Container,
} from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./pages/home/HomePage";
import Footer from "./components/Footer";
import "./App.css";
import Room from "./pages/room/Room";
import PageNotFound from "./pages/404/404Page";
import BG from "./assets/bg-pattern.png";
const font = "'Karla', sans-serif";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: font,
  },
});
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <div
        style={{
          backgroundImage: "linear-gradient(to left, #7b4397, #dc2430)",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "repeat",
          }}
        >
          <NavBar />

          <Router>
            <Switch>
              <Route path="/r/:roomId" exact>
                <Room />
              </Route>

              <Route path="/" exact>
                <HomePage />
              </Route>

              <Route
                component={() => (
                  <Container maxWidth="md">
                    <PageNotFound />
                  </Container>
                )}
              />
            </Switch>
          </Router>
        </div>
      </div>
      <Footer />
    </MuiThemeProvider>
  );
}

export default App;
