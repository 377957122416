import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import screenShot from "../../assets/logo.png";
import DownloadButtons from "../../components/DownloadButtons";

function HomePage() {
  return (
    <div>
      <HeroImage />
    </div>
  );
}

function HeroImage() {
  return (
    <Container
      maxWidth="md"
      style={{
        width: "100%",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{
          minHeight: "80vh",
        }}
      >
        <Grid item>
          <img
            src={screenShot}
            alt="Screenshot"
            className="floating-image"
            style={{
              width: "200px",
            }}
          />
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            style={{
              color: "white",
              fontSize: "1.25rem",
              margin: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Play Callbreak at anytime. With or without friends.
          </Typography>
        </Grid>

        <Grid item>
          <DownloadButtons />
        </Grid>
      </Grid>
    </Container>
  );
}
export default HomePage;
