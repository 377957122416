import {
  Button,
  Container,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import DownloadButtons from "../../components/DownloadButtons";
import QRCode from "qrcode.react";
import { MdContentCopy } from "react-icons/md";
import { useParams } from "react-router-dom";
import { Slide } from "@material-ui/core";

import NotFound from "../404/404.svg";
import axios from "axios";

interface FirebaseResponse {
  shortLink: string;
}

function Room() {
  let { roomId } = useParams<{ roomId: string }>();

  const [snackbarOpen, setSnackBarOpen] = useState(false);
  const [url, setUrl] = useState(window.location.href);
  const [loading, setLoading] = useState(false);

  const showSnackbar = () => {
    setSnackBarOpen(true);
  };

  function isiOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Slide}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleClose}
        message="Room Id copied Successfully!"
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="space-evenly"
        style={{ minHeight: "85vh" }}
      >
        {isValidRoomID(roomId) && (
          <Grid
            item
            style={{
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <QRCode
              value={window.location.href}
              style={{ width: "100%", height: "100%" }}
              renderAs={"svg"}
              includeMargin={false}
            />
          </Grid>
        )}
        {isValidRoomID(roomId) && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item>
                <Typography
                  style={{
                    letterSpacing: "3px",
                    fontWeight: "bolder",
                    fontSize: "2.4rem",
                    textTransform: "uppercase",
                  }}
                >
                  {roomId}
                </Typography>
              </Grid>

              <Grid item>
                <IconButton
                  onClick={() => {
                    showSnackbar();
                    navigator.clipboard.writeText(roomId);
                  }}
                >
                  <MdContentCopy />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item>
              <Typography variant="caption">
                Already have Callbreak installed?
              </Typography>
            </Grid>
            <Grid item>
              {/* TODO handle this when app is released in ios */}
              {!isiOS() ? (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  onClick={() => {
                    if (window.location.href !== url) {
                      window.location.href = url;
                    } else {
                      setLoading(true);

                      axios
                        .post(
                          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_KEY}`,
                          {
                            dynamicLinkInfo: {
                              domainUriPrefix: "https://clbrk.page.link",
                              link: window.location.href,
                              androidInfo: {
                                androidPackageName:
                                  "com.khellabs.callbreakmultiplayer",
                              },
                              iosInfo: {
                                iosBundleId: "com.khellabs.callbreakmultip",
                              },
                            },
                          },
                          {
                            headers: {
                              "Content-Type": "application/json",
                            },
                          }
                        )
                        .then(function (response) {
                          const data = response.data as FirebaseResponse;
                          setUrl(data.shortLink);
                          setLoading(false);
                          window.location.href = data.shortLink;
                        })
                        .catch(function (error) {
                          setLoading(false);
                          console.log(error);
                        });
                    }
                  }}
                  style={{
                    width: "220px",
                    borderRadius: "200px",
                    backgroundColor: "white",
                    color: "#70664b",
                  }}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}
                  >
                    Join Room
                  </Typography>
                </Button>
              ) : (
                <Button
                  href={
                    window.location.href.toLowerCase().includes("play")
                      ? `https://clbrk.com/r/${roomId}`
                      : `https://play.clbrk.com/r/${roomId}`
                  }
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disableElevation
                  target="_blank"
                  type="submit"
                  style={{
                    width: "220px",
                    borderRadius: "200px",
                    backgroundColor: "white",
                    color: "#70664b",
                  }}
                >
                  <Typography
                    variant="button"
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                    }}
                  >
                    Join Room
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        {!isValidRoomID(roomId) && (
          <Grid item>
            <Container
              style={{
                width: "250px",
              }}
            >
              <img
                src={NotFound}
                alt="Screenshot"
                className="floating-image"
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
              />
            </Container>
          </Grid>
        )}
        {!isValidRoomID(roomId) && (
          <Grid item>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
              }}
            >
              Room Not Found
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="caption" align="center">
              Don’t have a Callbreak Multiplayer? Download the App now!
            </Typography>
          </Grid>

          <DownloadButtons />
        </Grid>
      </Grid>

      <Backdrop
        open={loading}
        style={{
          zIndex: 10,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

function isValidRoomID(id: string): boolean {
  const letters = /^[A-Za-z]+$/;
  if (id.match(letters)) {
    return id.length === 6;
  } else {
    return false;
  }
}

export default Room;
