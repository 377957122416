import { Button, Container, Grid, Typography } from "@material-ui/core";
import { AiFillFacebook, AiOutlineInstagram } from "react-icons/ai";
import { IconContext } from "react-icons";
import { IconButton } from "@material-ui/core";

function Footer() {
  return (
    <div
      style={{
        background: "#000000",
        paddingBottom: "16px",
        position: "absolute",
        width: "100%",

        // left: 0,
        // bottom: 0,
        // right: 0,
      }}
    >
      <Container
        maxWidth="md"
        style={{
          marginBottom: "16px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography
              style={{
                color: "white",
                fontWeight: "bold",
                marginTop: "16px",
              }}
            >
              Follow us on
            </Typography>
          </Grid>

          <Grid item>
            <SocialMediaButtons />
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            height: "1px",
            marginTop: "16px",
            marginBottom: "16px",
            background: "#fafafa",
          }}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="text"
              href="https://callbreakmultiplayer.com/terms-of-service"
              style={{
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Terms of Service
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="text"
              href="https://callbreakmultiplayer.com/privacy-policy"
              style={{
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Privacy Policy
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

interface ScocialMediaLink {
  link: string;
  icon: any;
}
function SocialMediaButtons() {
  const data: ScocialMediaLink[] = [
    { link: "https://www.facebook.com/callbreak.mp", icon: <AiFillFacebook /> },
    {
      link: "https://www.instagram.com/callbreak.mp",
      icon: <AiOutlineInstagram />,
    },
  ];
  return (
    <IconContext.Provider
      value={{
        color: "white",
        className: "global-class-name",
        size: "24px",
      }}
    >
      <Grid container>
        {data.map((item: ScocialMediaLink) => (
          <Grid item key={item.link}>
            <IconButton href={item.link} target="_blank">
              {item.icon}
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </IconContext.Provider>
  );
}
export default Footer;
